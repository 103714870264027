<template>
  <div>
    <h1>Hello test route!!</h1>
    <span>{{ $route.params.path }}</span>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
